import { RootStore } from "src/stores/RootStore";
import { action, computed, observable } from "mobx";
import { Organization } from "src/api";
import { ConfigureOrganizationStore } from "src/components/ConfigureOrganization/ConfigureOrganizationStore";
import { ReactTableStore } from "src/stores/common/ReactTableStore";

export class UserOrganizationListStore extends ReactTableStore<Organization> {
    @observable organization: ConfigureOrganizationStore;
    @observable organizationListTab = "organization-list";
    @observable organizationAddTab = "organization-add";
    @observable currentTab?: string;

    constructor(private readonly root: RootStore) {
        super();
        this.currentTab = this.organizationListTab;
        this.organization = new ConfigureOrganizationStore(root, async (id, dto) => {
            const response = await this.root.userRpc.userOrganization.create(dto);
            if (response.success) {
                await this.root.userOrganizationListStore.refresh();
                this.root.userOrganizationListStore.currentTab = this.root.userOrganizationListStore.organizationListTab;
            }
            return response;
        });
        this.pageSize = 3;
    }

    @computed get showOrganizationListTab(): boolean {
        return this.currentTab === this.organizationListTab;
    }

    @computed get showOrganizationAddTab(): boolean {
        return this.currentTab === this.organizationAddTab;
    }

    @action async refresh(): Promise<void> {
        const organizations = await this.root.userRpc.userOrganization.getAll(this.skip, this.take, "");
        this.fillItems(organizations.items, organizations.totalCount);
        await this.organization.load(null);
    }

    @action async open(organizationId: string): Promise<void> {
        await this.root.organizationLoginStore.login(organizationId);
    }

    @action async delete(organizationId: string): Promise<void> {
        await this.root.userRpc.userOrganization.delete(organizationId);
        this.root.organizationLoginStore.logOut();
        await this.refresh();
    }
}
